export interface Endpoints {
    bannerManagerCreate: string;
    bannerManagerRetrieveAll: string;
    bannerManagerDelete: string;
    bannerManagerUpdate: string;
    b2cPolicies: {
      names: {
        signUpSignIn: string
      },
      authorities: {
        signUpSignIn: {
          authority: string
        }
      },
      authorityDomain: string
      redirectUri: string
      clientId: string
    }
  } 
  
  export const loadEndpoints = async (): Promise<Endpoints> => {
    const response = await fetch('/Endpoints.json');
    if (!response.ok) {
      throw new Error(`Failed to load configuration: ${response.status}`);
    }
    return response.json();
  };
  